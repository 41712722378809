import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'es6-shim' ; 
import * as serviceWorker from './serviceWorker';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import GeneralProviderUtils from '../src/context/contextUtils.context';
import './assets/css/rsuite.css';

ReactDOM.render(
  <>
    <GeneralProviderUtils>
      <ReactNotification />
      <App />
    </GeneralProviderUtils>
  </>,
  document.getElementById('root')
);

serviceWorker.unregister();
