/* eslint-disable */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './assets/css/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import Spinner from './components/spinner.component';
import { useClearCache } from 'react-clear-cache';

const Main = React.lazy(() => import('./layout/main.layout'));
const Login = React.lazy(() => import('./views/login/login.view'));

function App() {

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage()
    }
  },[])

  return (
    <Router>
      <React.Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/login" name="Login" component={Login} />
          <Route path="/" name="Main" render={props => <Main {...props}/>} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
