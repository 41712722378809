import React from 'react';

const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="spinner-grow text-primary" style={{ backgroundColor: '#ff6d25' }} role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    </div>
  )
}

export default Spinner;